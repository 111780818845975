@use "sass:math";

/* media query mixin */
@mixin for-size($size) {
	@if $size == phone-sm-down {
		@media (max-width: ($screen-width-phone-sm - 0.0625rem)) {
			@content;
		}
	} @else if $size == phone-sm-up {
		@media (min-width: $screen-width-phone-sm) {
			@content;
		}
	} @else if $size == phone-down {
		@media (max-width: ($screen-width-phone - 0.0625rem)) {
			@content;
		}
	} @else if $size == phone-up {
		@media (min-width: $screen-width-phone) {
			@content;
		}
	} @else if $size == tablet-up {
		@media (min-width: $screen-width-tablet) {
			@content;
		}
	} @else if $size == tablet-down {
		@media (max-width: ($screen-width-tablet - 0.0625rem)) {
			@content;
		}
	} @else if $size == tablet-landscape-down {
		@media (max-width: ($screen-width-tablet-landscape - 0.0625rem)) {
			@content;
		}
	} @else if $size == tablet-landscape-up {
		@media (min-width: $screen-width-tablet-landscape) {
			@content;
		}
	} @else if $size == desktop-up {
		@media (min-width: $screen-width-desktop) {
			@content;
		}
	} @else if $size == desktop-down {
		@media (max-width: ($screen-width-desktop - 0.0625rem)) {
			@content;
		}
	} @else if $size == desktop-large-up {
		@media (min-width: $screen-width-desktop-large) {
			@content;
		}
	} @else if $size == desktop-large-down {
		@media (max-width: ($screen-width-desktop-large - 0.0625rem)) {
			@content;
		}
	}
}
/* get rid of list formatting */
@mixin normalize-list() {
	list-style: none;
	padding: 0;
	margin: 0;
}
/* prevent long links from breaking out of container */
@mixin no-break-out($hyphens: false) {
	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	@if $hyphens == true {
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}
}

@function toRem($value) {
	$remValue: ($value / 16) + rem;
	@return $remValue;
}

@function calculateRem($size) {
	$remSize: $size / $base-font-size;
	@return $remSize * 1rem;
}

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
}

@mixin font-awesome() {
	font-family: $font-family-font-awesome;
	font-weight: 900;
}

/* creates a colored circle with an icon in the middle */
@mixin icon-circle(
	$icon,
	$color,
	$background-color,
	$size: 3rem,
	$position: ":before"
) {
	font-family: $font-family-main !important;
	font-weight: 900;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row-reverse;
	// @include fa-icon-solid($icon);
	&#{$position} {
		font-family: $font-family-font-awesome;
		content: #{"'" + $icon + "'"};
		display: flex;
		align-items: center;
		justify-content: center;
		width: $size;
		height: $size;
		border-radius: 50%;
		background: $background-color;
		color: $color;
	}
}

/* formats the placeholder text within an input field */
@mixin format-placeholder() {
	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		@content;
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		@content;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		@content;
	}
	&:-moz-placeholder {
		/* Firefox 18- */
		@content;
	}
}

@mixin bgImg() {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

@mixin center() {
	display: flex;
	justify-content: center;
	align-items: center;
}
